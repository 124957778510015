import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TrbmContentAlt from "src/components/modules/trbmcontentAlt"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import SimplyTemp from "src/components/modules/simplyTemp"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-border.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;
const Img2 = () => <img className="as" src="/img/as-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;

export default function prdbest() {

  let module;
     module = null;
    if(isChrome()){
      module = <TrbmContentAlt data={prdbestData}><Img />
      <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
      </TrbmContentAlt>
     }
     else if(isIeEdge()){
        module = <TrbmContentAlt data={prdbestData}>
        <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
        </TrbmContentAlt>
      }
      else if(isFirefox()){
        module = <TrbmContentAlt data={prdbestData}>
        <CtaDisclosure data={prdbestData}></CtaDisclosure></TrbmContentAlt>
      }
      else if(isSafari()){
       module = <SimplyTemp data={prdbestData}><Img2 /></SimplyTemp>
      }

     let css;
       if(isFirefox()){
         css = ` .trbmcontentAlt-module--trbmcontentAlt {
           color: white !important;
         }
         .trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets {
           padding-left: 0 !important;
           width: 522px;
         }
         .trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets li {
           font-size: 15px;
         }
         .cws {
           display: none!important;
         }
           .trbmcontentAlt-module--trbmcontentAlt button {
             padding: 20px 80px;
           }
         #ctadisclosure-module--ctadisclaimer {
             padding: 25px 7% 0!important;
           }
         }`

         ;
       }
       else if(isIeEdge()){
         css = `.trbmcontentAlt-module--trbmcontentAlt button {
           font-weight: bold!important;
           margin-bottom: 30px;
         }
         .trbmcontentAlt-module--trbmcontentAlt button:hover {
          transform: none !important;
          cursor: auto !important;
          }
          .trbmcontentAlt-module--trbmcontentAlt button {
          cursor:auto !important; 
        }
         
         
         `;
       }
       else{
         css = `.simply-module--simplyWrap .simply-module--atf h3 {
           color: #f0f0f0;
         }

         .trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets {
           padding-left: 0!important;
         }
         .cws {
           width: 200px!important;
           display: block!important;
           margin: 20px auto 10px!important;
           cursor: pointer;
         }
         .as {
          width: 150px!important;
          display: block!important;
          margin: 20px auto 10px!important;
          cursor: pointer;
        }
         #ctadisclosure-module--ctadisclaimer {
           padding: 0px 7%!important;
           padding-bottom: 0!important;}

         }`;
       }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
  background-image: url(/img/manualindex-bg.jpeg)!important;
  background-color: #ccc!important;
  background-repeat: no-repeat!important;
  -webkit-background-size: cover !important;;
  -moz-background-size: cover !important;;
  -o-background-size: cover !important;;
  background-size: cover !important;;
  font-family: 'Open Sans', sans-serif;
}

button.trbm_cta {
  font-weight: normal!important;
  font-size: 30px!important;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 7%!important;
  padding-bottom: 0!important;
  color: #f1f1f1;
}

.trbmcontentAlt-module--card {
border: 1px solid #727272!important;
background: rgba(30, 30, 30, 0.8)!important;
}

.trbmcontentAlt-module--bottom { display: none!important;}

.trbmcontentAlt-module--bullets li, #ctadisclosure-module--ctadisclaimer {
  color: #fff!important;
}

.trbmcontentAlt-module--hc_param {
  display: block!important;
    font-weight: bold!important;
    font-size: 25pt!important;
}

.trbmcontentAlt-module--logo {
  width: 130px;
}

.simply-module--simplyWrap .simply-module--atf {
  background:none !important;
}

.simply-module--simplyWrap .simply-module--atf {
  max-height:auto !important;
  background: rgba(30, 30, 30, 0.8)!important;
  width: 800px !important;
  border-radius: 10px;
  border: 1px solid #727272;
  text-align: center;
  box-sizing: border-box;
  padding: 20px !important;
  margin: 40px auto !important;
}

.simply-module--minHeight {
  min-height:728px;
  max-height:1080px;
}

.trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--hc_param, .simply-module--simplyWrap .simply-module--atf h1 {
  color: #f1f1f1;
}

`}
   </style>

   <style type="text/css">
     {css}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      {module}
    </HomepageLayout>
  )
}
